/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.min.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "/node_modules/ngx-spinner/animations/ball-clip-rotate.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "/src/assets/styles/variables.scss";
@import "/src/assets/styles/custom-theme.scss";
@import "/src/assets/styles/form-validation.scss";
@import "primeicons/primeicons.css";

html {
  height: 100%;
  min-height: 100%;
}

iframe#launcher {
  left: 80px !important;
}

body {
  background: $body-bg-color;
  overflow: hidden;
  height: inherit;
  font-family: "Segoe UI", Arial, sans-serif;
}

app-header {
  display: block;
}

.text-primary {
  color: $primary-color !important;
}

.text-white {
  color: $white-color;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.font-lg {
  font-size: 1.5rem;
}

h1 {
  color: $primary-color;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.list-main-container,
.scheduler-main-container {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  .header-table {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .search-info {
      display: flex;
      position: relative;
      align-items: center;
      .searchbar {
        position: relative;
        .search-button {
          border: 0;
          width: 30px;
          background-color: transparent;
          position: absolute;
          right: 7px;
          top: -2px;
          height: 100%;
        }
        input {
          width: 400px;
          border: solid 1px #ccc;
          border-radius: 5px;
          height: 40px;
          outline: 0;
          padding: 0 30px 0 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: $primary-light-gray;
        }
      }
      .btn-common {
        background: $primary-color;
        border-radius: 20px;
        height: 35px;
        display: flex;
        align-items: center;
        color: $white-color;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        padding: 0 15px;
        outline: 0;
        border: 0;
      }
    }
  }
  .table-content {
    width: 100%;
    margin-top: 25px;
    position: relative;
    .table {
      width: 1850px;
    }
    table {
      border: 0;
      padding: 0;
      width: 100%;
      tr {
        border: 0;
      }
      th {
        color: $primary-light-shade-1;
        @extend %font-style-1;
        i {
          cursor: pointer;
        }
      }
      td {
        @extend %font-style-3;
        color: $primary-gray-headers;
      }
      th,
      td {
        padding: 15px 15px;
      }
      thead {
        background: rgb(234, 240, 252);
      }
      tbody {
        tr {
          background: $white-color;
          &:nth-of-type(even) {
            background: rgb(232, 233, 233);
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    li {
      margin: 0 5px;
      a {
        background: #f6f6f6;
        border: 1px solid #eaeaea;
        border-radius: 2px;
        @extend %font-style-1;
        text-transform: capitalize;
        color: $primary-light-blue;
        height: 37px;
      }
      &.active {
        a {
          background-color: $primary-color;
          color: $white-color;
          border-radius: 2px;
        }
      }
      &.disabled {
        display: none;
      }
      &.pagination-next,
      &.pagination-prev {
        a {
          font-size: 34px;
          line-height: 15px;
          height: 37px;
          background-color: transparent;
          border: 0;
          color: $primary-color;
        }
      }
      &.pagination-first,
      &.pagination-last {
        display: none;
      }
    }
  }
}

.tabs-content {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-bottom: 30px;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #212121;
    position: relative;
    margin-right: 15px;
    padding: 0 6px 10px 6px;
    cursor: pointer;
    &.active {
      font-weight: 600;
      &:after {
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        background-color: #212121;
      }
    }
  }
}

.bread {
  width: 100%;
  padding-bottom: 30px;
  ul {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      display: inline-flex;
      padding-right: 5px;
      @extend %font-style-4;
      color: $primary-gray-headers;
      align-items: center;
      &.parent:hover {
        cursor: pointer;
      }
      a {
        @extend %font-style-4;
        color: $primary-gray-headers;
        text-decoration: none;
      }
      &.active {
        @extend %font-style-4;
        color: $primary-gray-headers;
        a {
          @extend %font-style-6;
          color: $primary-gray-headers;
        }
      }
    }
  }
}

.executed-status {
  color: $green-color;
  font-weight: 600;
}

.inprogress-status {
  color: $dark-yellow;
  font-weight: 600;
}

.scheduled-status {
  color: $primary-color;
  font-weight: 600;
}

.draft-status {
  color: black;
  font-weight: 600;
}

.created-status {
  color: $light-green;
  font-weight: 600;
}

.failed-text {
  color: #ff0000;
  font-weight: 600;
}

/* ng-select custom css */
ng-select {
  &.is-invalid {
    .ng-select-container {
      border-color: #dc3545;
    }
  }
  &.table-dropdown {
    .ng-select-container {
      min-height: 32px !important;
    }
  }
  .ng-select-container {
    min-height: 48px !important;
    border-radius: 2px !important;
    .ng-value-container {
      .ng-placeholder {
        top: 10px !important;
      }
      .ng-input > input {
        height: 36px;
      }
      .ng-value-disabled {
        color: $primary-gray-headers;
        opacity: 0.7;
      }
    }
  }
  ng-dropdown-panel {
    .ng-option input {
      margin-right: 5px;
    }
  }
}

.modal.show .modal-dialog {
  width: 35%;
}

// Added fonts for Quill / p-editor
.ql-font-arial {
  font-family: "Arial", sans-serif;
}
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}
.ql-font-georgia {
  font-family: "Georgia", serif;
}
.ql-font-verdana {
  font-family: "Verdana", sans-serif;
}
.ql-font-calibri {
  font-family: "Calibri", sans-serif;
}
.ql-font-garamond {
  font-family: "Garamond", serif;
}
.ql-font-tahoma {
  font-family: "Tahoma", sans-serif;
}
.ql-font-times-new-roman {
  font-family: "Times New Roman", sans-serif;
}

.custom-overlay-panel {
  width: 33%;
  .popup-content {
    padding: 5px;
    width: 100%;

    .row-options {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 4px 0px;
    }

    .row-option {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border: 1px solid #ccc;
      padding: 5px;
      position: relative;
      .column {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-right: 5px;
        border-radius: 4px;
        border: 1px dashed #ccc;
        background-color: #f9f9f9;
        text-align: center;
      }
    }

    .row-option:hover {
      border: 2px solid #007bff;
      background-color: #fff;
    }

    .row-option:hover .column {
      opacity: 0;
    }

    .add-text {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: black;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .row-option:hover .add-text {
      opacity: 1;
    }
  }
}
