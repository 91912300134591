@import "/src/assets/styles/variables.scss";

.validation-error {
  color: $error-color;
  font-size: $font13;
}

.form-label {
  .required {
    color: $error-color;
  }
}
