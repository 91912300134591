$primary-color: #33378f;
$primary-light-blue: #6366ad;
$body-bg-color: #f8f9fa;
$label-options-color: #66696b;
$primary-gray-headers: #4a4a4d;
$primary-light-shade-1: #606060;
$primary-light-gray: #97999b;
$disabled-bg-color: #e6e6e6;
$disabled-color: #b1b1b1;
$disabled-border-color: #d5d5d5;
$heading-dark-color: #212121;
$light-grey-table-color: #e6e6e69e;

/* Colors */
$white-color: #ffffff;
$black-color: #000000;
$green-color: green;
$red-color: red;
$yellow-color: #dada12;
$light-grey: #ebebeb;
$greylight: #999999;
$linkblue2: #3b86ff;
$error-color: #e24c4c;
$dark-yellow: #b7b700d9;
$light-green: #5b9c37;

/* Fonts Styles */
%font-style-1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

%font-style-2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}

%font-style-3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

%font-style-4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

%font-style-5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

%font-style-6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

/* Mixins */

@mixin ellipsis($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

/* Font Variables */
$roboto_light: "Roboto_light";

/* Font Sizes */
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font18: 18px;
