/* PrimeNG CSS Customized */

::-webkit-scrollbar {
  padding: 0 2px;
}

::-webkit-scrollbar:vertical {
  width: 0.5rem;
  padding: 0 2px;
}

::-webkit-scrollbar:horizontal {
  height: 0.5rem;
  padding: 0 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  background-color: #aaa !important;
  outline: none;
  border-radius: rem(5);
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
}

::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  border-width: 1px 2px;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 50px;
  border-width: 1px 2px;
}

.p-button.p-button-filled {
  background: $primary-color;
  border-radius: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  color: $white-color;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  padding: 0 15px;
  cursor: pointer;
  outline: 0;
  border: 0;
  &:disabled {
    background: $primary-color;
  }
}

.p-button.p-button-outlined {
  color: $primary-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  gap: 2px;
  align-items: center;
  height: 40px;
  cursor: pointer;
  border: solid 1px $primary-color;
  text-decoration: none;
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:enabled:active,
.p-button.p-button-filled:enabled:hover,
.p-button.p-button-filled:enabled:active,
.p-button-sm,
.p-button:enabled:hover {
  background: $primary-color;
  color: $white-color;
}

.p-button.p-button-outlined:disabled {
  color: darkgrey;
}

.p-button.p-button-filled:focus {
  box-shadow: none;
}

.p-button-label {
  font-weight: 400;
}

p-button + i {
  margin-left: 10px;
  color: $primary-light-gray;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}

.disabled .p-disabled {
  background: #e6e6e6;
  border-color: #d5d5d5;
}

.p-inputtext:not(.p-dropdown-filter):enabled:hover,
.p-multiselect:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-dropdown:not(.p-disabled):hover {
  border-color: $primary-color;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  border-radius: 24px;
  background-color: #fff;
  color: #1e293b;
  margin-right: 5px;
  padding: 0.25rem 1.2rem;
  border: 2px solid #97999b;
  .p-multiselect-token-label {
    color: #1e293b;
  }
}

.p-button:focus,
.p-button.p-button-link:enabled:hover {
  box-shadow: none !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $primary-color;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: $primary-color !important;
  color: $white-color !important;
}

.p-listbox:not(.p-disabled)
  .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  background: $primary-color !important;
  color: $white-color !important;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  box-shadow: none !important;
}

p-scrollpanel {
  .p-scrollpanel {
    width: 100%;
    height: 100%;
  }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #2196f3 !important;
  color: $white-color !important;
}

.p-steps-item .p-menuitem-link {
  flex-direction: row !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0 !important;
  background: $body-bg-color;
  z-index: 1;
  margin-left: 5px;
  font-size: 14px;
}

.p-steps .p-steps-item:before {
  margin-top: 0rem !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  background: #9e9e9e;
  color: $white-color !important;
  min-width: 24px !important;
  height: 24px !important;
  font-size: 12px !important;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent !important;
  outline: none !important;
  outline-offset: 0em !important;
  box-shadow: none !important;
}

.p-menu .p-menuitem-link:not(.p-disabled) .p-menuitem-text {
  color: $primary-color;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: $primary-color;
  .p-menuitem-text {
    color: $white-color;
  }
}

.p-dropdown.p-disabled {
  background-color: #f5f5f5 !important;
  color: #999 !important;
  border: 1px solid #cccccc;
  .p-dropdown-label {
    cursor: not-allowed !important;
  }
  .p-dropdown-trigger {
    cursor: not-allowed !important;
  }
}

.p-dialog {
  .p-link:focus {
    box-shadow: none !important;
  }
  .p-dialog-header {
    .p-dialog-title {
      font-weight: 600;
      color: $primary-color;
    }
  }
}

.p-toast .p-toast-detail {
  overflow-wrap: anywhere;
}

.rcs-calender-open {
  .p-datepicker:not(.p-datepicker-inline) {
    overflow-y: scroll !important;
    height: 550px !important;
  }
}
